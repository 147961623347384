<template>
  <a
    :href="to"
    :title="title"
    target="_blank"
    rel="noopener"
  >
    <!-- @slot 링크 텍스트 -->
    <slot></slot>
  </a>
</template>

<script>
/**
 * 외부 링크
 */
export default {
  name: 'ExternalLink',
  props: {
    /**
     * 외부 URL
     */
    to: {
      type: String,
      required: true,
    },
    /**
     * 링크 Tooltip, 접근성 정보
     */
    title: {
      type: String,
      default: '새창으로 열립니다.',
    },
  },
};
</script>

<style scoped>
</style>

<!-- https://vue-styleguidist.github.io/docs/Documenting.html -->
<docs>

기본 사용 예시 :
```jsx
<ExternalLink to="https://google.com/">구글</ExternalLink>
```

이미지 사용 (slot) 예시 :
```jsx
<ExternalLink to="https://www.dreamplus.asia/">
  <img src="https://www.dreamplus.asia/static/og_img_v1.jpg" width="480" height="240">
</ExternalLink>
```
</docs>
