<template>
  <footer>
    <div class="footer-inner-top">
      <div class="footer-logo-wrap">
        <router-link class="footer-logo" @click.native="activeMenu" to="/">
          <span class="blind">DREAMPLUS</span>
        </router-link>
      </div>

      <div class="right-area">
        <ul class="footer-terms">
          <!-- 이용약관 -->
          <li>
            <router-link to="/terms">{{ $t('footer.agreement') }}</router-link>
          </li>
          <!-- 개인정보 처리방침 -->
          <li>
            <router-link to="/privacy">{{ $t('footer.private') }}</router-link>
          </li>
        </ul>

        <ul class="footer-sns">
          <!-- facebook -->
          <li>
            <ExternalLink
              to="https://www.facebook.com/dreamplusasia"
              title="facebook"
              class="footer-facebook">
              <span class="blind">facebook</span>
            </ExternalLink>
          </li>

          <!-- instagram -->
          <li>
            <ExternalLink
              to="https://www.instagram.com/dreamplusasia"
              title="instagram"
              class="footer-instagram">
              <span class="blind">instagram</span>
            </ExternalLink>
          </li>

          <!-- youtube -->
          <li>
            <ExternalLink
              to="https://www.youtube.com/DREAMPLUS"
              title="youtube"
              class="footer-youtube">
              <span class="blind">youtube</span>
            </ExternalLink>
          </li>

          <!-- blog -->
          <li>
            <ExternalLink
              to="https://blog.naver.com/hanwhadreamplus"
              title="blog"
              class="footer-blog">
              <span class="blind">blog</span>
            </ExternalLink>
          </li>
        </ul>
      </div>
    </div>

    <div class="footer-inner-bottom">
      <div class="footer-link-area">
        <ul class="footer-link">
          <!-- 한화생명 -->
          <li>
            <ExternalLink
              to="https://www.hanwhalife.com/"
              title="한화생명"
              class="footer-hwlife">
              <span class="blind">한화생명</span>
            </ExternalLink>
          </li>

          <!-- LIFEPLUS -->
          <li>
            <ExternalLink
              to="https://www.lifeplus.co.kr/"
              title="LIFEPLUS"
              class="footer-lifeplus">
              <span class="blind">LIFEPLUS</span>
            </ExternalLink>
          </li>

          <!-- DREAM PARK -->
          <!-- <li>
            <ExternalLink
              to="https://dreampark.hanwhalife.com/"
              title="DREAM PARK"
              class="footer-dreampark">
              <span class="blind">DREAM PARK</span>
            </ExternalLink>
          </li> -->

          <!-- MEMBER'S LOUNGE  -->
          <li>
            <ExternalLink
              to="https://founders.dreamplus.asia/"
              title="MEMBER'S LOUNGE"
              class="footer-members-rounge">
              <span class="blind">MEMBER'S LOUNGE</span>
            </ExternalLink>
          </li>

          <!-- DREAMHAUS 서비스 종료 -->
          <!-- <li>
            <ExternalLink
              to="https://dreamin.career/dreamhaus"
              title="DREAMHAUS"
              class="footer-dreamhaus">
              <span class="blind">DREAMHAUS</span>
            </ExternalLink>
          </li> -->

          <!-- dreamin 서비스 종료 -->
          <!-- <li>
            <ExternalLink
              to="https://dreamin.career/"
              title="dreamin"
              class="footer-dreamin">
              <span class="blind">dreamin</span>
            </ExternalLink>
          </li> -->
        </ul>
      </div>

      <div class="copy-area">
        <small class="copy">COPYRIGHT ⓒ 2020 DREAMPLUS ALL RIGHTS RESERVED</small>
        <dl class="contact">
          <dt>CONTACT</dt>
          <dd>
            <ExternalLink
              to="mailto:contact@dreamplus.asia"
              title="CONTACT"
            >contact@dreamplus.asia</ExternalLink>
          </dd>
        </dl>
      </div>
    </div>
  </footer>
</template>

<script>
import ExternalLink from '@/components/base/ExternalLink.vue';

export default {
  name: 'GlobalFooter',
  components: {
    ExternalLink,
  },
  methods: {
    activeMenu() {
      this.$emit('activeMenu', 0);
    },
  },
};
</script>

<style scoped>
</style>
